import React from 'react';
import character from '../../data/creature.json';
import './guess.scss';

const Guess = ({ randomChampionIndex, selectedChampion }) => {

    function isEqual(array1, array2) {
        // Convert single objects to arrays with a single element
        array1 = Array.isArray(array1) ? array1 : [array1];
        array2 = Array.isArray(array2) ? array2 : [array2];
    
        let matchCount = 0;
    
        for (let i = 0; i < array1.length; i++) {
            if (isObjectInArray(array1[i], array2)) {
                matchCount++;
            }
        }
    
        let maxlength = Math.max(array1.length, array2.length);
    
        if (matchCount === 0) {
            return 0; // No objects in array1 match objects in array2
        } else if (matchCount === maxlength) {
            return 1; // All objects in array1 match objects in array2
        } else {
            return 2; // Some objects in array1 match objects in array2
        }
    }
    
    function isObjectInArray(obj, array) {
        for (let i = 0; i < array.length; i++) {
            // Check if the object in the array matches the given object
            if (isObjectEqual(obj, array[i])) {
                return true;
            }
        }
        return false;
    }
    
    function isObjectEqual(obj1, obj2) {
        // Assuming objects are simple and have no nested properties
        // You might need a more complex comparison for nested objects
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    const isNameMatch = character.data[selectedChampion].name === character.data[randomChampionIndex].name;
    const isBehaviorMatch = isEqual(character.data[selectedChampion].behavior, character.data[randomChampionIndex].behavior);
    const isHealthMatch = isEqual(character.data[selectedChampion].health, character.data[randomChampionIndex].health);
    const isSizeMatch = isEqual(character.data[selectedChampion].size, character.data[randomChampionIndex].size);
    const isFoodpipsMatch = isEqual(character.data[selectedChampion].foodpips, character.data[randomChampionIndex].foodpips);
    const isHabitatMatch = isEqual(character.data[selectedChampion].habitat, character.data[randomChampionIndex].habitat);
    const isDietMatch = isEqual(character.data[selectedChampion].diet, character.data[randomChampionIndex].diet);
    const isPointsMatch = isEqual(character.data[selectedChampion].points, character.data[randomChampionIndex].points);;

    return (
        <div className="guess_container">
            <div className={`guess_trait `}>
                <img src={"img/thumbnail/" + character.data[selectedChampion].thumbnail} alt="" />
                {/* <p>{character.data[selectedChampion].name}</p> */}
            </div>
            <div className={`guess_trait ${isBehaviorMatch === 2 ? 'guess_partial' : isBehaviorMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].behavior)
                    ? character.data[selectedChampion].behavior.join(", ")
                    : character.data[selectedChampion].behavior}
                </p>
            </div>
            <div className={`guess_trait ${isHealthMatch === 2 ? 'guess_partial' : isHealthMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].health)
                    ? character.data[selectedChampion].health.join(", ")
                    : character.data[selectedChampion].health}
                </p>
            </div>
            <div className={`guess_trait ${isSizeMatch === 2 ? 'guess_partial' : isSizeMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].size)
                    ? character.data[selectedChampion].size.join(", ")
                    : character.data[selectedChampion].size}
                </p>
            </div>
            <div className={`guess_trait ${isFoodpipsMatch === 2 ? 'guess_partial' : isFoodpipsMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].foodpips)
                    ? character.data[selectedChampion].foodpips.join(", ")
                    : character.data[selectedChampion].foodpips}
                </p>
            </div>
            <div className={`guess_trait ${isHabitatMatch === 2 ? 'guess_partial' : isHabitatMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].habitat)
                    ? character.data[selectedChampion].habitat.join(", ")
                    : character.data[selectedChampion].habitat}
                </p>
            </div>
            <div className={`guess_trait ${isDietMatch === 2 ? 'guess_partial' : isDietMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].diet)
                    ? character.data[selectedChampion].diet.join(", ")
                    : character.data[selectedChampion].diet}
                </p>
            </div>
            <div className={`guess_trait ${isPointsMatch === 2 ? 'guess_partial' : isPointsMatch === 1 ? 'guess_correct' : 'guess_wrong'} borderright`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].points)
                    ? character.data[selectedChampion].points.join(", ")
                    : character.data[selectedChampion].points}
                </p>
            </div>
        </div>
    )
}

export default Guess;